import React from 'react'
import { Link } from 'gatsby'
import './style.scss'
class Navi extends React.Component {
  constructor() {
    super()
    this.state = { loading: false }
  }
  componentDidMount() {
    const _this = this
    setTimeout(() => {
      _this.setState({
        loading: true,
      })
    }, 400)
  }
  render() {
    const { loading } = this.state
    const { stickyStyle } = this.props
    const { location, title } = this.props
    return (
      <nav
        style={stickyStyle}
        className={`navbar navbar-expand-lg navbar-light ${
          loading ? 'active' : ''
        } ${location.pathname === '/' ? '' : 'static'}`}
      >
        <div className="container">
          <Link to="/?ref=self" className="navbar-brand">
            <svg
              className="brand d-inline-block d-xl-none"
              width="52px"
              height="52px"
              viewBox="0 0 208 210"
            >
              <g
                id="Page-1"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
              >
                <g id="1024" transform="translate(-116.000000, -95.000000)">
                  <g
                    id="Group-2"
                    transform="translate(-8541.000000, 100.000000)"
                  >
                    <g id="Group" transform="translate(8662.000000, 0.000000)">
                      <path
                        d="M3,120 C3,120 10.9121747,72 63.5,72 C116.087825,72 124,120 124,120 L3,120 Z"
                        id="Path-2"
                        stroke="#000000"
                        strokeWidth="7"
                        fill="#F5C249"
                      ></path>
                      <path
                        d="M127,123 L193,123 C186.613271,143.990615 179.346494,158.563397 171.199669,166.718344 C163.052844,174.873292 148.319621,183.967177 127,194 L127,123 Z"
                        id="Path-6"
                        fill="#AE333C"
                      ></path>
                      <path
                        d="M125,5 L125,120.241398 L193.648553,121 C199.446923,93.3771411 194.52176,67.3410829 178.873065,42.8918254 C163.806508,23.3444828 145.84882,10.7138743 125,5 Z"
                        id="Path-5"
                        fill="#376193"
                      ></path>
                      <ellipse
                        id="Oval"
                        stroke="#000000"
                        strokeWidth="10"
                        cx="99"
                        cy="100"
                        rx="99"
                        ry="100"
                      ></ellipse>
                      <path
                        d="M124,2.31350482 L124,196.736043"
                        id="Line"
                        stroke="#000000"
                        strokeWidth="8"
                        strokeLinecap="square"
                      ></path>
                      <path
                        d="M4.31006494,120 L194.689935,120"
                        id="Line"
                        stroke="#000000"
                        strokeWidth="7"
                        strokeLinecap="square"
                      ></path>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
            <div className="d-inline-block align-middle ml-2 ml-xl-0">
              <span>Shinonome</span>
              <sub className="d-none d-xl-block">
                全人類が革新的な夢を実現する文化を創る
              </sub>
            </div>
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <Link className="nav-link" to="/about">
                  ご挨拶
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/service">
                  サービス
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/member">
                  メンバー
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/company">
                  会社概要
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/contact">
                  お問い合わせ
                </Link>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="https://blog.shinonome.io/"
                  target="_blank"
                >
                  ブログ
                </a>
              </li>
              {/* <li className="nav-item">
              <a className="nav-link" href="#">会社概要</a>
            </li>
            <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                団体に興味がある方
              </a>
              <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                <a className="dropdown-item" href="#">学生の方</a>
                <a className="dropdown-item" href="#">企業の方</a>
                <div className="dropdown-divider"></div>
                <a className="dropdown-item" href="#">大学の方</a>
              </div>
            </li> */}
            </ul>
          </div>
        </div>
      </nav>
      // <header
      //   style={stickyStyle}
      //   className={`brand-name ${loading ? 'active' : ''} ${
      //     location.pathname === '/' ? '' : 'static'
      //   }`}
      // >
      //   <div className="content">
      //     <h1>
      //       <Link to="/?ref=self">
      //         Shinonome
      //         <sub>全人類が革新的な夢を実現するための文化を創る</sub>
      //       </Link>
      //     </h1>
      //     <nav className="navigation">
      //       <ul>
      //         <li>
      //           <Link to="/about">私たちについて</Link>
      //         </li>
      //         <li>
      //           <Link to="/company">会社概要</Link>
      //         </li>
      //         <li>採用について</li>
      //         <li>大学関係者の方</li>
      //         <li>お問い合わせ</li>
      //       </ul>
      //     </nav>
      //   </div>
      // </header>
      // <nav className="navbar navbar-expand navbar-dark flex-column flex-md-row bg-primary">
      //   <div className="container">
      //     <Link className="text-center" to="/">
      //       <h1 className="navbar-brand mb-0">{title}</h1>
      //     </Link>
      //     <div className="navbar-nav-scroll">
      //       <ul className="navbar-nav bd-navbar-nav flex-row">
      //         <li
      //           className={
      //             location.pathname === '/' ? 'nav-item active' : 'nav-item'
      //           }
      //         >
      //           <Link to="/" className="nav-link">
      //             Home
      //           </Link>
      //         </li>
      //         <li
      //           className={
      //             location.pathname === '/profile/'
      //               ? 'nav-item active'
      //               : 'nav-item'
      //           }
      //         >
      //           <Link to="/profile/" className="nav-link">
      //             Profile
      //           </Link>
      //         </li>
      //       </ul>
      //     </div>
      //     <div className="navbar-nav flex-row ml-md-auto d-none d-md-flex" />
      //   </div>
      // </nav>
    )
  }
}

export default Navi
