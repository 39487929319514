import React from 'react'
import './style.scss'
import { Link } from 'gatsby'
import bgLogo from './bg-logo.svg'

class Navi extends React.Component {
  render() {
    return (
      <>
        <aside className="sidebar d-none d-xl-block">
          <div className="sidebar-content">
            <Link to="/?ref=self" className="brand-wrapper">
              <svg
                className="brand"
                width="208px"
                height="210px"
                viewBox="0 0 208 210"
              >
                <g
                  id="Page-1"
                  stroke="none"
                  strokeWidth="1"
                  fill="none"
                  fillRule="evenodd"
                >
                  <g id="1024" transform="translate(-116.000000, -95.000000)">
                    <g
                      id="Group-2"
                      transform="translate(-8541.000000, 100.000000)"
                    >
                      <g
                        id="Group"
                        transform="translate(8662.000000, 0.000000)"
                      >
                        <path
                          d="M3,120 C3,120 10.9121747,72 63.5,72 C116.087825,72 124,120 124,120 L3,120 Z"
                          id="Path-2"
                          stroke="#000000"
                          strokeWidth="7"
                          fill="#F5C249"
                        ></path>
                        <path
                          d="M127,123 L193,123 C186.613271,143.990615 179.346494,158.563397 171.199669,166.718344 C163.052844,174.873292 148.319621,183.967177 127,194 L127,123 Z"
                          id="Path-6"
                          fill="#AE333C"
                        ></path>
                        <path
                          d="M125,5 L125,120.241398 L193.648553,121 C199.446923,93.3771411 194.52176,67.3410829 178.873065,42.8918254 C163.806508,23.3444828 145.84882,10.7138743 125,5 Z"
                          id="Path-5"
                          fill="#376193"
                        ></path>
                        <ellipse
                          id="Oval"
                          stroke="#000000"
                          strokeWidth="10"
                          cx="99"
                          cy="100"
                          rx="99"
                          ry="100"
                        ></ellipse>
                        <path
                          d="M124,2.31350482 L124,196.736043"
                          id="Line"
                          stroke="#000000"
                          strokeWidth="8"
                          strokeLinecap="square"
                        ></path>
                        <path
                          d="M4.31006494,120 L194.689935,120"
                          id="Line"
                          stroke="#000000"
                          strokeWidth="7"
                          strokeLinecap="square"
                        ></path>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
            </Link>
          </div>
        </aside>
      </>
    )
  }
}

export default Navi
