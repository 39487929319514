import React from 'react'
import Helmet from 'react-helmet'
// import get from 'lodash/get'

const Meta = ({ site, title }) => {
  if (typeof window !== 'undefined') {
    window.location.href = "https://shinonome.com"; //TODO: Shinonome - gamerinshaft add - redirect new hp
  }
  title = title ? `${title} | ${site.title}` : site.title
  return (
    <Helmet
      title={title}
      meta={[
        { name: 'twitter:card', content: 'summary_large_image' },
        // {
        //   name: 'twitter:site',
        //   content: `@${get(site, 'twitter')}`,
        // },
        { property: 'og:title', content: title },
        { property: 'og:type', content: 'website' },
        {
          property: 'og:description',
          content: site.description,
        },
        {
          property: 'og:url',
          content: `${site.siteUrl}`,
        },
        {
          property: 'og:image',
          content: `${site.siteUrl}/img/shinonome-bg.png`,
        },
      ]}
    />
  )
}
export default Meta
