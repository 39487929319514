import { Link } from 'gatsby'
import React from 'react'
import './style.scss'

const Footer = ({ author, title }) => (
  <footer className="footer mt-5">
    <div className="divider divider--curve d-none d-md-block">
      <svg viewBox="0 0 1695 72" preserveAspectRatio="none">
        <path
          d="M0 0c282.5 45 565 67.5 847.5 67.5S1412.5 45 1695 0v72H0V0z"
          fill="#f5f5f5"
          fillRule="evenodd"
        ></path>
      </svg>
    </div>
    <div className="footer-contents container py-5">
      <div className="footer-nav">
        <h5 className="category text-center">SITE MAP</h5>
        <div className="link-wrap">
          <ul className="list-unstyled d-flex justify-content-center flex-sm-row flex-column flex-wrap">
            <li className="d-inline-block">
              <Link className="nav-link" to="/?ref=self">
                トップ
              </Link>
            </li>
            <li className="d-inline-block">
              <Link className="nav-link" to="/about">
                ご挨拶
              </Link>
            </li>
            <li className="d-inline-block">
              <Link className="nav-link" to="/service">
                サービス
              </Link>
            </li>
            <li className="d-inline-block">
              <Link className="nav-link" to="/member">
                メンバー
              </Link>
            </li>
            <li className="d-inline-block">
              <Link className="nav-link" to="/company">
                会社概要
              </Link>
            </li>
            <li className="d-inline-block">
              <a
                className="nav-link"
                href="https://blog.shinonome.io/"
                target="_blank"
              >
                ブログ
              </a>
              {/* <Link className="nav-link" to="/shop">
                <span>ショップ</span>
                <small className="memo">準備中</small>
              </Link> */}
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/contact">
                お問い合わせ
              </Link>
            </li>
          </ul>
        </div>
        <div className="extra-nav">
          <div className="list-wrap">
            <ul className="list-unstyled d-flex flex-wrap flex-sm-row flex-column justify-content-center">
              <li>
                <Link className="nav-link" to="/privacy">
                  当社が取扱う個人情報について
                </Link>
              </li>
              <li>
                <Link className="nav-link" to="/privacy-policy">
                  個人情報保護方針
                </Link>
              </li>
              <li>
                <Link className="nav-link" to="/security-policy">
                  情報セキュリティに関する方針
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="share">
        <h5 className="category text-center">SHARE</h5>
        <div className="sns-icon-wrap d-flex justify-content-center">
          <button className="sns-icon twitter">
            <a
              href="https://twitter.com/share?url=https://about.shinonome.io/&text=株式会社Shinonomeのウェブサイト&hashtags=shinonome,web"
              // url={共有したいurl} text={デフォルトの文章} hashtags={ハッシュタグ1,ハッシュタグ2}
              rel="nofollow"
              target="_blank"
            >
              <span className="fa fa-twitter"></span>
            </a>
          </button>
          <button className="sns-icon facebook">
            <a
              href="http://www.facebook.com/share.php?u=https://about.shinonome.io"
              // u={共有したいurl}
              rel="nofollow"
              target="_blank"
            >
              <span className="fa fa-facebook"></span>
            </a>
          </button>
          <div
            className="fb-like"
            data-href="https://www.facebook.com/shinonomeinc"
            data-width="120px"
            data-layout="button_count"
            data-action="like"
            data-size="large"
            data-show-faces="true"
            data-share="true"
          ></div>
          {/* <button className="sns-icon facebook-good">
            <div className="fb-like" data-href="https://www.facebook.com/shinonomeinc" data-width="120px" data-layout="button_count" data-action="like" data-size="large" data-show-faces="false" data-share="false"></div>
            <a href="">
              <span className="fas fa-thumbs-up mr-1"></span>
              <span>56</span>
            </a>
          </button> */}
        </div>
      </div>
      <div className="logo d-flex justify-content-center">
        <span className="d-flex flex-column">
          <svg width="176px" height="42px" viewBox="0 0 956 210">
            <g
              id="Page-1"
              stroke="none"
              strokeWidth="1"
              fill="none"
              fillRule="evenodd"
            >
              <g id="1024" transform="translate(-116.000000, -95.000000)">
                <g id="Group-2" transform="translate(121.000000, 100.000000)">
                  <g id="Group">
                    <path
                      d="M3,120 C3,120 10.9121747,72 63.5,72 C116.087825,72 124,120 124,120 L3,120 Z"
                      id="Path-2"
                      stroke="#000000"
                      strokeWidth="7"
                      fill="#F5C249"
                    ></path>
                    <path
                      d="M127,123 L193,123 C186.613271,143.990615 179.346494,158.563397 171.199669,166.718344 C163.052844,174.873292 148.319621,183.967177 127,194 L127,123 Z"
                      id="Path-6"
                      fill="#AE333C"
                    ></path>
                    <path
                      d="M125,5 L125,120.241398 L193.648553,121 C199.446923,93.3771411 194.52176,67.3410829 178.873065,42.8918254 C163.806508,23.3444828 145.84882,10.7138743 125,5 Z"
                      id="Path-5"
                      fill="#376193"
                    ></path>
                    <ellipse
                      id="Oval"
                      stroke="#000000"
                      strokeWidth="10"
                      cx="99"
                      cy="100"
                      rx="99"
                      ry="100"
                    ></ellipse>
                    <path
                      d="M124,2.31350482 L124,196.736043"
                      id="Line"
                      stroke="#000000"
                      strokeWidth="6"
                      strokeLinecap="square"
                    ></path>
                    <path
                      d="M4.31006494,122 L194.689935,122"
                      id="Line"
                      stroke="#000000"
                      strokeWidth="6"
                      strokeLinecap="square"
                    ></path>
                  </g>
                  <path
                    d="M251.175781,149.212891 L251.175781,133.833984 C260.822965,137.870463 269.07744,139.888672 275.939453,139.888672 C280.742863,139.888672 284.527004,138.819021 287.291992,136.679688 C290.05698,134.540354 291.439453,131.634133 291.439453,127.960938 C291.439453,125.054673 290.571623,122.602549 288.835938,120.604492 C287.100252,118.606435 283.810571,116.23504 278.966797,113.490234 L272.972656,110.160156 C264.940064,105.6393 259.268897,101.310242 255.958984,97.1728516 C252.649072,93.0354611 250.994141,88.2018506 250.994141,82.671875 C250.994141,75.2851193 253.708632,69.3515849 259.137695,64.8710938 C264.566759,60.3906026 271.741492,58.1503906 280.662109,58.1503906 C287.806676,58.1503906 295.85933,59.1796772 304.820312,61.2382812 L304.820312,75.5878906 C295.657506,72.1568839 288.412136,70.4414062 283.083984,70.4414062 C278.966776,70.4414062 275.667005,71.3596913 273.18457,73.1962891 C270.702136,75.0328868 269.460938,77.4446465 269.460938,80.4316406 C269.460938,82.8938925 270.328767,85.0533761 272.064453,86.9101562 C273.800139,88.7669364 277.069637,91.0475125 281.873047,93.7519531 L288.291016,97.3242188 C296.92908,102.167993 302.882796,106.628235 306.152344,110.705078 C309.421891,114.781921 311.056641,119.746716 311.056641,125.599609 C311.056641,133.914755 307.968781,140.43357 301.792969,145.15625 C295.617157,149.87893 287.100315,152.240234 276.242188,152.240234 C269.057256,152.240234 260.701871,151.23113 251.175781,149.212891 Z"
                    id="Path"
                    fill="#000000"
                    fillRule="nonzero"
                  ></path>
                  <path
                    d="M327.828125,150 L327.828125,54.3964844 L345.75,54.3964844 L345.75,96.0527344 C351.602894,86.6881042 359.150995,82.0058594 368.394531,82.0058594 C374.328155,82.0058594 379.0104,83.8827937 382.441406,87.6367188 C385.872413,91.3906438 387.587891,96.5168946 387.587891,103.015625 L387.587891,150 L369.666016,150 L369.666016,107.435547 C369.666016,99.8873321 367.163437,96.1132812 362.158203,96.1132812 C356.466768,96.1132812 350.997422,100.129517 345.75,108.162109 L345.75,150 L327.828125,150 Z"
                    id="Path"
                    fill="#000000"
                    fillRule="nonzero"
                  ></path>
                  <polygon
                    id="Path"
                    fill="#000000"
                    fillRule="nonzero"
                    points="410.050781 150 410.050781 83.5195312 427.972656 83.5195312 427.972656 150"
                  ></polygon>
                  <polygon
                    id="Path"
                    fill="#000000"
                    fillRule="nonzero"
                    points="410.050781 72.3183594 410.050781 57.3632812 427.972656 57.3632812 427.972656 72.3183594"
                  ></polygon>
                  <path
                    d="M451.162109,150 L451.162109,83.5195312 L469.083984,83.5195312 L469.083984,96.0527344 C474.936878,86.6881042 482.48498,82.0058594 491.728516,82.0058594 C497.662139,82.0058594 502.344384,83.8827937 505.775391,87.6367188 C509.206397,91.3906438 510.921875,96.5168946 510.921875,103.015625 L510.921875,150 L493,150 L493,107.435547 C493,99.8873321 490.497421,96.1132812 485.492188,96.1132812 C479.800753,96.1132812 474.331407,100.129517 469.083984,108.162109 L469.083984,150 L451.162109,150 Z"
                    id="Path"
                    fill="#000000"
                    fillRule="nonzero"
                  ></path>
                  <path
                    d="M561.720703,82.0058594 C572.336642,82.0058594 580.7223,85.1139012 586.87793,91.3300781 C593.03356,97.546255 596.111328,105.982369 596.111328,116.638672 C596.111328,127.537164 593.023468,136.074188 586.847656,142.25 C580.671844,148.425812 572.155002,151.513672 561.296875,151.513672 C550.923125,151.513672 542.678742,148.375357 536.563477,142.098633 C530.448212,135.821909 527.390625,127.375704 527.390625,116.759766 C527.390625,106.022733 530.468394,97.546255 536.624024,91.3300781 C542.779653,85.1139012 551.14513,82.0058594 561.720703,82.0058594 Z M561.720703,93.2070312 C556.998023,93.2070312 553.274428,95.3059686 550.549805,99.5039062 C547.825182,103.701844 546.462891,109.45374 546.462891,116.759766 C546.462891,123.985062 547.815091,129.716776 550.519531,133.955078 C553.223972,138.193381 556.917294,140.3125 561.599609,140.3125 C571.89263,140.3125 577.039063,132.421303 577.039063,116.638672 C577.039063,109.413375 575.676772,103.701844 572.952149,99.5039062 C570.227526,95.3059686 566.483748,93.2070312 561.720703,93.2070312 Z"
                    id="Combined-Shape"
                    fill="#000000"
                    fillRule="nonzero"
                  ></path>
                  <path
                    d="M613.367188,150 L613.367188,83.5195312 L631.289063,83.5195312 L631.289063,96.0527344 C637.141956,86.6881042 644.690058,82.0058594 653.933594,82.0058594 C659.867217,82.0058594 664.549462,83.8827937 667.980469,87.6367188 C671.411476,91.3906438 673.126953,96.5168946 673.126953,103.015625 L673.126953,150 L655.205078,150 L655.205078,107.435547 C655.205078,99.8873321 652.702499,96.1132812 647.697266,96.1132812 C642.005831,96.1132812 636.536485,100.129517 631.289063,108.162109 L631.289063,150 L613.367188,150 Z"
                    id="Path"
                    fill="#000000"
                    fillRule="nonzero"
                  ></path>
                  <path
                    d="M723.925781,82.0058594 C734.54172,82.0058594 742.927378,85.1139012 749.083008,91.3300781 C755.238638,97.546255 758.316406,105.982369 758.316406,116.638672 C758.316406,127.537164 755.228547,136.074188 749.052735,142.25 C742.876922,148.425812 734.36008,151.513672 723.501953,151.513672 C713.128203,151.513672 704.88382,148.375357 698.768555,142.098633 C692.65329,135.821909 689.595703,127.375704 689.595703,116.759766 C689.595703,106.022733 692.673472,97.546255 698.829102,91.3300781 C704.984731,85.1139012 713.350208,82.0058594 723.925781,82.0058594 Z M723.925781,93.2070312 C719.203102,93.2070312 715.479506,95.3059686 712.754883,99.5039062 C710.03026,103.701844 708.667969,109.45374 708.667969,116.759766 C708.667969,123.985062 710.020169,129.716776 712.72461,133.955078 C715.42905,138.193381 719.122373,140.3125 723.804688,140.3125 C734.097708,140.3125 739.244141,132.421303 739.244141,116.638672 C739.244141,109.413375 737.88185,103.701844 735.157227,99.5039062 C732.432604,95.3059686 728.688826,93.2070312 723.925781,93.2070312 Z"
                    id="Combined-Shape"
                    fill="#000000"
                    fillRule="nonzero"
                  ></path>
                  <path
                    d="M775.572266,150 L775.572266,83.5195312 L792.767578,83.5195312 L792.767578,96.0527344 C798.015,86.6881042 805.482374,82.0058594 815.169922,82.0058594 C820.175155,82.0058594 824.292302,83.2369669 827.521485,85.6992188 C830.750667,88.1614706 832.728512,91.612608 833.455078,96.0527344 C839.671255,86.6881042 847.178993,82.0058594 855.978516,82.0058594 C868.128316,82.0058594 874.203125,88.7063132 874.203125,102.107422 L874.203125,150 L857.007813,150 L857.007813,107.980469 C857.007813,100.109336 854.384141,96.1738281 849.136719,96.1738281 C843.768202,96.1738281 838.541041,100.048789 833.455078,107.798828 L833.455078,150 L816.259766,150 L816.259766,107.980469 C816.259766,100.068971 813.615912,96.1132812 808.328125,96.1132812 C803.040338,96.1132812 797.853541,100.008425 792.767578,107.798828 L792.767578,150 L775.572266,150 Z"
                    id="Path"
                    fill="#000000"
                    fillRule="nonzero"
                  ></path>
                  <path
                    d="M922.701172,82.0058594 C932.429085,82.0058594 939.533181,85.073537 944.013672,91.2089844 C948.494163,97.3444317 950.734375,107.052017 950.734375,120.332031 L909.501953,120.332031 C910.712897,133.006574 917.675718,139.34375 930.390625,139.34375 C936.404978,139.34375 943.18616,137.951186 950.734375,135.166016 L950.734375,147.759766 C942.217406,150.262382 934.14457,151.513672 926.515625,151.513672 C915.415309,151.513672 906.656282,148.365266 900.238281,142.068359 C893.820281,135.771453 890.611328,127.173883 890.611328,116.275391 C890.611328,105.98237 893.547822,97.6976225 899.420899,91.4208984 C905.293975,85.1441743 913.053988,82.0058594 922.701172,82.0058594 Z M922.15625,93.2070312 C914.72913,93.2070312 910.430345,98.8781985 909.259766,110.220703 L909.259766,110.220703 L933.115235,110.220703 C933.115235,98.8781985 929.462276,93.2070312 922.15625,93.2070312 Z"
                    id="Combined-Shape"
                    fill="#000000"
                    fillRule="nonzero"
                  ></path>
                </g>
              </g>
            </g>
          </svg>
          <small className="ml-2 text-muted">© 2022 Shinonome, inc.</small>
        </span>
      </div>
      {/* <div className="col-6 col-md">
          <h5>Features</h5>
          <ul className="list-unstyled text-small">
            <li>
              <a className="text-muted" href="#">
                Cool stuff
              </a>
            </li>
            <li>
              <a className="text-muted" href="#">
                Random feature
              </a>
            </li>
            <li>
              <a className="text-muted" href="#">
                Team feature
              </a>
            </li>
            <li>
              <a className="text-muted" href="#">
                Stuff for developers
              </a>
            </li>
            <li>
              <a className="text-muted" href="#">
                Another one
              </a>
            </li>
            <li>
              <a className="text-muted" href="#">
                Last time
              </a>
            </li>
          </ul>
        </div>
        <div className="col-6 col-md">
          <h5>Resources</h5>
          <ul className="list-unstyled text-small">
            <li>
              <a className="text-muted" href="#">
                Resource
              </a>
            </li>
            <li>
              <a className="text-muted" href="#">
                Resource name
              </a>
            </li>
            <li>
              <a className="text-muted" href="#">
                Another resource
              </a>
            </li>
            <li>
              <a className="text-muted" href="#">
                Final resource
              </a>
            </li>
          </ul>
        </div>
        <div className="col-6 col-md">
          <h5>Resources</h5>
          <ul className="list-unstyled text-small">
            <li>
              <a className="text-muted" href="#">
                Business
              </a>
            </li>
            <li>
              <a className="text-muted" href="#">
                Education
              </a>
            </li>
            <li>
              <a className="text-muted" href="#">
                Government
              </a>
            </li>
            <li>
              <a className="text-muted" href="#">
                Gaming
              </a>
            </li>
          </ul>
        </div>
        <div className="col-6 col-md">
          <h5>About</h5>
          <ul className="list-unstyled text-small">
            <li>
              <a className="text-muted" href="#">
                Team
              </a>
            </li>
            <li>
              <a className="text-muted" href="#">
                Locations
              </a>
            </li>
            <li>
              <a className="text-muted" href="#">
                Privacy
              </a>
            </li>
            <li>
              <a className="text-muted" href="#">
                Terms
              </a>
            </li>
          </ul>
        </div> */}
    </div>
    {/* <div className="divider divider--curve"><svg viewBox="0 0 1695 72" preserveAspectRatio="none"><path d="M0 0c282.5 45 565 67.5 847.5 67.5S1412.5 45 1695 0v72H0V0z" fill="#f5f5f5" fillRule="evenodd"></path></svg></div>    <div className="container">
      <svg width="956px" height="210px" viewBox="0 0 956 210">
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="1024" transform="translate(-116.000000, -95.000000)">
            <g id="Group-2" transform="translate(121.000000, 100.000000)">
              <g id="Group">
                <path d="M3,120 C3,120 10.9121747,72 63.5,72 C116.087825,72 124,120 124,120 L3,120 Z" id="Path-2" stroke="#000000" strokeWidth="7" fill="#F5C249"></path>
                <path d="M127,123 L193,123 C186.613271,143.990615 179.346494,158.563397 171.199669,166.718344 C163.052844,174.873292 148.319621,183.967177 127,194 L127,123 Z" id="Path-6" fill="#AE333C"></path>
                <path d="M125,5 L125,120.241398 L193.648553,121 C199.446923,93.3771411 194.52176,67.3410829 178.873065,42.8918254 C163.806508,23.3444828 145.84882,10.7138743 125,5 Z" id="Path-5" fill="#376193"></path>
                <ellipse id="Oval" stroke="#000000" strokeWidth="10" cx="99" cy="100" rx="99" ry="100"></ellipse>
                <path d="M124,2.31350482 L124,196.736043" id="Line" stroke="#000000" strokeWidth="6" strokeLinecap="square"></path>
                <path d="M4.31006494,122 L194.689935,122" id="Line" stroke="#000000" strokeWidth="6" strokeLinecap="square"></path>
              </g>
              <path d="M251.175781,149.212891 L251.175781,133.833984 C260.822965,137.870463 269.07744,139.888672 275.939453,139.888672 C280.742863,139.888672 284.527004,138.819021 287.291992,136.679688 C290.05698,134.540354 291.439453,131.634133 291.439453,127.960938 C291.439453,125.054673 290.571623,122.602549 288.835938,120.604492 C287.100252,118.606435 283.810571,116.23504 278.966797,113.490234 L272.972656,110.160156 C264.940064,105.6393 259.268897,101.310242 255.958984,97.1728516 C252.649072,93.0354611 250.994141,88.2018506 250.994141,82.671875 C250.994141,75.2851193 253.708632,69.3515849 259.137695,64.8710938 C264.566759,60.3906026 271.741492,58.1503906 280.662109,58.1503906 C287.806676,58.1503906 295.85933,59.1796772 304.820312,61.2382812 L304.820312,75.5878906 C295.657506,72.1568839 288.412136,70.4414062 283.083984,70.4414062 C278.966776,70.4414062 275.667005,71.3596913 273.18457,73.1962891 C270.702136,75.0328868 269.460938,77.4446465 269.460938,80.4316406 C269.460938,82.8938925 270.328767,85.0533761 272.064453,86.9101562 C273.800139,88.7669364 277.069637,91.0475125 281.873047,93.7519531 L288.291016,97.3242188 C296.92908,102.167993 302.882796,106.628235 306.152344,110.705078 C309.421891,114.781921 311.056641,119.746716 311.056641,125.599609 C311.056641,133.914755 307.968781,140.43357 301.792969,145.15625 C295.617157,149.87893 287.100315,152.240234 276.242188,152.240234 C269.057256,152.240234 260.701871,151.23113 251.175781,149.212891 Z" id="Path" fill="#000000" fillRule="nonzero"></path>
              <path d="M327.828125,150 L327.828125,54.3964844 L345.75,54.3964844 L345.75,96.0527344 C351.602894,86.6881042 359.150995,82.0058594 368.394531,82.0058594 C374.328155,82.0058594 379.0104,83.8827937 382.441406,87.6367188 C385.872413,91.3906438 387.587891,96.5168946 387.587891,103.015625 L387.587891,150 L369.666016,150 L369.666016,107.435547 C369.666016,99.8873321 367.163437,96.1132812 362.158203,96.1132812 C356.466768,96.1132812 350.997422,100.129517 345.75,108.162109 L345.75,150 L327.828125,150 Z" id="Path" fill="#000000" fillRule="nonzero"></path>
              <polygon id="Path" fill="#000000" fillRule="nonzero" points="410.050781 150 410.050781 83.5195312 427.972656 83.5195312 427.972656 150"></polygon>
              <polygon id="Path" fill="#000000" fillRule="nonzero" points="410.050781 72.3183594 410.050781 57.3632812 427.972656 57.3632812 427.972656 72.3183594"></polygon>
              <path d="M451.162109,150 L451.162109,83.5195312 L469.083984,83.5195312 L469.083984,96.0527344 C474.936878,86.6881042 482.48498,82.0058594 491.728516,82.0058594 C497.662139,82.0058594 502.344384,83.8827937 505.775391,87.6367188 C509.206397,91.3906438 510.921875,96.5168946 510.921875,103.015625 L510.921875,150 L493,150 L493,107.435547 C493,99.8873321 490.497421,96.1132812 485.492188,96.1132812 C479.800753,96.1132812 474.331407,100.129517 469.083984,108.162109 L469.083984,150 L451.162109,150 Z" id="Path" fill="#000000" fillRule="nonzero"></path>
              <path d="M561.720703,82.0058594 C572.336642,82.0058594 580.7223,85.1139012 586.87793,91.3300781 C593.03356,97.546255 596.111328,105.982369 596.111328,116.638672 C596.111328,127.537164 593.023468,136.074188 586.847656,142.25 C580.671844,148.425812 572.155002,151.513672 561.296875,151.513672 C550.923125,151.513672 542.678742,148.375357 536.563477,142.098633 C530.448212,135.821909 527.390625,127.375704 527.390625,116.759766 C527.390625,106.022733 530.468394,97.546255 536.624024,91.3300781 C542.779653,85.1139012 551.14513,82.0058594 561.720703,82.0058594 Z M561.720703,93.2070312 C556.998023,93.2070312 553.274428,95.3059686 550.549805,99.5039062 C547.825182,103.701844 546.462891,109.45374 546.462891,116.759766 C546.462891,123.985062 547.815091,129.716776 550.519531,133.955078 C553.223972,138.193381 556.917294,140.3125 561.599609,140.3125 C571.89263,140.3125 577.039063,132.421303 577.039063,116.638672 C577.039063,109.413375 575.676772,103.701844 572.952149,99.5039062 C570.227526,95.3059686 566.483748,93.2070312 561.720703,93.2070312 Z" id="Combined-Shape" fill="#000000" fillRule="nonzero"></path>
              <path d="M613.367188,150 L613.367188,83.5195312 L631.289063,83.5195312 L631.289063,96.0527344 C637.141956,86.6881042 644.690058,82.0058594 653.933594,82.0058594 C659.867217,82.0058594 664.549462,83.8827937 667.980469,87.6367188 C671.411476,91.3906438 673.126953,96.5168946 673.126953,103.015625 L673.126953,150 L655.205078,150 L655.205078,107.435547 C655.205078,99.8873321 652.702499,96.1132812 647.697266,96.1132812 C642.005831,96.1132812 636.536485,100.129517 631.289063,108.162109 L631.289063,150 L613.367188,150 Z" id="Path" fill="#000000" fillRule="nonzero"></path>
              <path d="M723.925781,82.0058594 C734.54172,82.0058594 742.927378,85.1139012 749.083008,91.3300781 C755.238638,97.546255 758.316406,105.982369 758.316406,116.638672 C758.316406,127.537164 755.228547,136.074188 749.052735,142.25 C742.876922,148.425812 734.36008,151.513672 723.501953,151.513672 C713.128203,151.513672 704.88382,148.375357 698.768555,142.098633 C692.65329,135.821909 689.595703,127.375704 689.595703,116.759766 C689.595703,106.022733 692.673472,97.546255 698.829102,91.3300781 C704.984731,85.1139012 713.350208,82.0058594 723.925781,82.0058594 Z M723.925781,93.2070312 C719.203102,93.2070312 715.479506,95.3059686 712.754883,99.5039062 C710.03026,103.701844 708.667969,109.45374 708.667969,116.759766 C708.667969,123.985062 710.020169,129.716776 712.72461,133.955078 C715.42905,138.193381 719.122373,140.3125 723.804688,140.3125 C734.097708,140.3125 739.244141,132.421303 739.244141,116.638672 C739.244141,109.413375 737.88185,103.701844 735.157227,99.5039062 C732.432604,95.3059686 728.688826,93.2070312 723.925781,93.2070312 Z" id="Combined-Shape" fill="#000000" fillRule="nonzero"></path>
              <path d="M775.572266,150 L775.572266,83.5195312 L792.767578,83.5195312 L792.767578,96.0527344 C798.015,86.6881042 805.482374,82.0058594 815.169922,82.0058594 C820.175155,82.0058594 824.292302,83.2369669 827.521485,85.6992188 C830.750667,88.1614706 832.728512,91.612608 833.455078,96.0527344 C839.671255,86.6881042 847.178993,82.0058594 855.978516,82.0058594 C868.128316,82.0058594 874.203125,88.7063132 874.203125,102.107422 L874.203125,150 L857.007813,150 L857.007813,107.980469 C857.007813,100.109336 854.384141,96.1738281 849.136719,96.1738281 C843.768202,96.1738281 838.541041,100.048789 833.455078,107.798828 L833.455078,150 L816.259766,150 L816.259766,107.980469 C816.259766,100.068971 813.615912,96.1132812 808.328125,96.1132812 C803.040338,96.1132812 797.853541,100.008425 792.767578,107.798828 L792.767578,150 L775.572266,150 Z" id="Path" fill="#000000" fillRule="nonzero"></path>
              <path d="M922.701172,82.0058594 C932.429085,82.0058594 939.533181,85.073537 944.013672,91.2089844 C948.494163,97.3444317 950.734375,107.052017 950.734375,120.332031 L909.501953,120.332031 C910.712897,133.006574 917.675718,139.34375 930.390625,139.34375 C936.404978,139.34375 943.18616,137.951186 950.734375,135.166016 L950.734375,147.759766 C942.217406,150.262382 934.14457,151.513672 926.515625,151.513672 C915.415309,151.513672 906.656282,148.365266 900.238281,142.068359 C893.820281,135.771453 890.611328,127.173883 890.611328,116.275391 C890.611328,105.98237 893.547822,97.6976225 899.420899,91.4208984 C905.293975,85.1441743 913.053988,82.0058594 922.701172,82.0058594 Z M922.15625,93.2070312 C914.72913,93.2070312 910.430345,98.8781985 909.259766,110.220703 L909.259766,110.220703 L933.115235,110.220703 C933.115235,98.8781985 929.462276,93.2070312 922.15625,93.2070312 Z" id="Combined-Shape" fill="#000000" fillRule="nonzero"></path>
            </g>
          </g>
        </g>
      </svg>
      <hr className="border-primary" />
      <p>
        {title}
        <Link to="/profile/">
          <br />
          <strong>{author}</strong> on Profile
        </Link>
      </p>
    </div> */}
  </footer>
)

export default Footer
